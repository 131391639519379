import React from "react"
import styled from "styled-components"
import { StyledLink } from "../components/NavBar"
import { H2, SubHeading, P } from "../components/Heading"

const ArticleDiv = styled.div`
  margin: 0 0 40px 0;
`

const ArticleP = styled(P)`
  margin: 8px 0 0 0;
`

export const Article = ({ node }) => {
  return (
    <ArticleDiv>
      <StyledLink to={node.frontmatter.path}>
        <H2>{node.frontmatter.title}</H2>
      </StyledLink>
      <SubHeading>{node.frontmatter.date}</SubHeading>
      <ArticleP>{node.excerpt}</ArticleP>
    </ArticleDiv>
  )
}
