import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { Container, Content } from "../components/Container"
import { GlobalStyle } from "../components/GlobalStyle"
import { NavBar } from "../components/NavBar"
import { Article } from "../components/Article"

export default ({ data }) => {
  return (
    <>
      <GlobalStyle />

      <Helmet>
        <title>techuid</title>
        <meta name="Description" content="My homepage" />
        <html lang="en" />
      </Helmet>

      <NavBar />

      <Container>
        <Content>
          {data.allMarkdownRemark.edges.map(({ node }, index) => {
            return <Article key={index} node={node} />
          })}
        </Content>
      </Container>
    </>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt(pruneLength: 200)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            description
          }
        }
      }
    }
  }
`
