import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  margin: 0px auto;
  max-width: 600px;
  padding: 20px;
`

export const Content = styled.div`
  display: block;
  font-size: 120%;
  max-width: inherit;
  width: 100%;
`
