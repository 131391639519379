import styled from "styled-components"

export const H1 = styled.h1`
  font-weight: 400;
  margin-bottom: 4px;
  color: ${props => (props.color ? props.color : "black")};
`

export const H2 = styled.h2`
  font-weight: 400;
  margin-bottom: 0px;
  color: ${props => (props.color ? props.color : "black")};
`

export const H3 = styled.h3`
  font-weight: 400;
  margin-bottom: 0px;
  color: ${props => (props.color ? props.color : "black")};
`

export const SubHeading = styled.p`
  color: #555;
  font-size: 85%;
  margin: 0px;
  
`

export const P = styled.p`
  line-height: 1.54;
`
