import { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`
  html {
    overflow-y: scroll; 
  }

  body {
    margin 0px;
    padding 0px;
    height: 100vh;
    width: 100%;

    font-family: 'Lato', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`
