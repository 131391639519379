import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { H1 } from "../components/Heading"

const NavDiv = styled.div`
  display: block;
  width: 100%;

  height: 54px;

  line-height: 54px;
  font-family: 'Open Sans', sans-serif;

  background: white;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

const CenteredDiv = styled.div`
  display: flex;
  margin: 0px auto;
  max-width: 840px;
  padding-left: 15px;
  padding-right: 15px;
`

export const StyledLink = styled(props => <Link {...props} />)`
  text-decoration: none;
`

export const Heading = styled(props => <H1 {...props} />)`
  margin: 0px;
  color: black;
  font-weight: bold;
  font-size: 20px;
`

export const NavBar = () => {
  return (
    <NavDiv>
      <CenteredDiv>
        <StyledLink to="/">
          <Heading color="#2a2a2a">TECHUID</Heading>
        </StyledLink>
      </CenteredDiv>
    </NavDiv>
  )
}
